import React from 'react'

const Header = () => (
  <div className="downloads-header">
    <h1>
      <b>DOWNLOADS</b>
    </h1>
  </div>
)

export default Header
