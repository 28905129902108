import React from 'react'
import PropTypes from 'prop-types'

/* eslint-disable react/jsx-wrap-multilines */
const Products = ({
  downloadProspectHandler,
  accessScopeHandler,
  widthClass,
  scopeOnly,
}) => {
  return (
    <section className={`products ${widthClass}`}>
      <div className="row heading">
        <div className="col">{widthClass ? '' : 'PRODUCT'}</div>
        <div className="col">{widthClass ? '' : 'REQUIREMENTS'}</div>
        <div className="col" />
      </div>
      {widthClass || scopeOnly ? null : (
        <div className="row">
          <hr />
        </div>
      )}
      {scopeOnly ? null : (
        <div className="row">
          <div className="col title">
            <p>
              <b>Prospect Pro Plus</b>
            </p>
            <p>
              <b>Prospect Pro</b>
            </p>
            <p>
              <b>Prospect Basic</b>
            </p>
            <p>
              <b>Prospect Viewer</b>
            </p>
          </div>
          <div className="col">
            <ul>
              <li>Windows 7 SP1 64 bit or newer</li>
              <li>Oculus Rift, Meta Quest 2, HTC Vive, or Windows MR</li>
              <li>NVIDIA GTX 1070 or faster</li>
            </ul>
          </div>
          <div className="col">
            <button className="download-btn" onClick={downloadProspectHandler}>
              DOWNLOAD PROSPECT
            </button>
          </div>
        </div>
      )}
      {widthClass && scopeOnly ? null : (
        <div className="row">
          <hr />
        </div>
      )}
      <div className="row">
        <div className="col title">
          <b>Scope Pro</b>
        </div>
        <div className="col">
          <ul>
            <li>Android or iOS, iPhone 5+</li>
            <li>Cardboard or Gear VR</li>
          </ul>
        </div>
        <div className="col">
          <div className="desc-text">Add panoramas through Scope Library</div>
          <button className="download-btn" onClick={accessScopeHandler}>
            ACCESS SCOPE LIBRARY
          </button>
          <div className="viewer-dls">
            <div className="desc-text">
              To view panos in VR download Scope Viewer
            </div>
            <ul className="links">
              <li>
                <a
                  href="https://itunes.apple.com/us/app/iris-scope-view-3d-panoramas/id1106219077?mt=8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Scope Viewer for iOS
                </a>
              </li>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.irisvr.scope"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Scope Viewer for Android + Cardboard
                </a>
              </li>
              <li>
                <a
                  href="https://help.irisvr.com/hc/en-us/articles/221850487-GearVR-Access-for-Scope-App"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Scope Viewer for Android + GearVR
                </a>
              </li>
              <li>
                <a
                  href="https://help.irisvr.com/hc/en-us/articles/115004538787"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Scope Viewer for Android + Daydream
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row" />
    </section>
  )
}

Products.propTypes = {
  widthClass: PropTypes.string.isRequired,
  downloadProspectHandler: PropTypes.func.isRequired,
  accessScopeHandler: PropTypes.func.isRequired,
  scopeOnly: PropTypes.bool.isRequired,
}

export default Products
