import { getWindowLocationOrigin } from '../../utils/window'
import { generateMetaTags } from '../../utils/helmet'

const title = 'Download IrisVR Prospect and Scope'
const description =
  'Download IrisVR Prospect and Scope for free; one click VR for 3D files from architecture and construction.'
const url = `${getWindowLocationOrigin()}/downloads`
const image = 'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Iris.jpg'
const imageWidth = 1200
const imageHeight = 630

export default generateMetaTags({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
})
