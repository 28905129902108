import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Products from './Products'
import { getDomain } from '../../../../utils/url'

/* eslint-disable no-confusing-arrow */
class ProductsContainer extends Component {
  downloadProspectHandler = () => {
    window.location = 'https://prospect-releases.irisvr.com/ProspectSetup.exe'
  }

  accessScopeHandler = () => {
    window.location = `https://account.${getDomain()}/scope`
  }

  render() {
    const { widthClass, scopeOnly } = this.props
    return (
      <Products
        widthClass={widthClass}
        downloadProspectHandler={this.downloadProspectHandler}
        accessScopeHandler={this.accessScopeHandler}
        scopeOnly={scopeOnly}
      />
    )
  }
}

ProductsContainer.propTypes = {
  widthClass: PropTypes.string.isRequired,
  scopeOnly: PropTypes.bool.isRequired,
}

export default ProductsContainer
