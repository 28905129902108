import React from 'react'
import { Layout } from '../Shared'
import Header from './sections/Header'
import ProductsContainer from './sections/Products/ProductsContainer'
import helmetProps from './helmetProps'
import breakpoints from './breakpoints'
import './Downloads.global.scss'
import queryString from 'query-string'

/** Container class for product downloads */
class Download extends React.Component {
  state = {
    scopeOnly: false,
  }

  UNSAFE_componentWillMount() {
    const queryParams = queryString.parse(this.props.location.search)
    if (queryParams.product === 'scope') this.setState({ scopeOnly: true })
  }

  render() {
    const { widthClass } = this.props
    const { scopeOnly } = this.state

    return (
      <div id="downloads">
        <Header />
        <ProductsContainer widthClass={widthClass} scopeOnly={scopeOnly} />
      </div>
    )
  }
}

export default props => (
  <Layout breakpoints={breakpoints}>
    <Download {...props} />
  </Layout>
)

export { helmetProps }
