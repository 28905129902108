import React from 'react'

import { Helmet } from '../components/Shared'
import { helmetProps } from '../components/Downloads'

export default React.memo(props => (
  <>
    <h1>Signup page</h1>

    <a href="/download">
      <button>Download</button>
    </a>
  </>
))
